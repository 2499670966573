import React from 'react';
import {Button} from "@dreebit/pv-components";

interface Props {
    linkButtonText: string,
    onClickLinkButton: () => void,
    description?: string,
}

const ExternalRedirectModalContent = ({linkButtonText, onClickLinkButton, description}: Props) => {
    return (
        <div className={"flex-col"}>
            <div>
                {description || ""}
            </div>
            <div className={'mt-24'}>
                <Button
                    onClick={onClickLinkButton}
                    style={{width: 'auto', cursor: 'pointer' }}
                    type="primary"
                    className={'full-width'}
                    icon={"arrow_forward"}
                >
                    {linkButtonText}
                </Button>
            </div>

        </div>
    );
}

export default ExternalRedirectModalContent;
