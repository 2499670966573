import React from 'react';
import LogoColored from './pfeifferColored';

//shown storybook controls
export interface IProps {
    opacity?: number,
    width?: number,
}

const PfeifferLogo = (props: IProps) => {
    const {opacity, width} = props;

    return <LogoColored width={width} fillOpacity={opacity}/>
};

PfeifferLogo.defaultProps = {
    mono: false,
    opacity: 100,
    width: 256,
    color: '',
};

export default PfeifferLogo;
